import React, { useState } from "react";
import {
  CloseButton,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Collapse,
  Alert,
} from "react-bootstrap";
import { formatter } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../../../services";
import PropTypes from "prop-types";

const Zelle = ({ setMethod, user, payment }) => {
  const navigate = useNavigate();

  const [paymentDone, setPaymentDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const confirmPayment = async () => {
    setLoading(true);
    const { data } = await API.base.createZellePayment({
      user,
      payment,
    });
    if (data.found === true) {
      navigate("/success", { state: { method: "zelle", payment } });
    } else if (data.found === false) {
      setOpen(true);
    } else if (data.found === "noMatch") {
      navigate("/paymentNotFound");
    }
    setLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      <Row>
        <Col className="d-flex mt-4 flex-column justify-content-center">
          <p className="mb-3">Realiza el pago por Zelle: </p>
          <p className="mb-0">
            Monto a pagar:{" "}
            <span className="fw-bold">{formatter.format(payment.amount)}$</span>
          </p>
          <p className="mb-3">
            A: <span className="fw-bold mb-4">pagos@pleni.app</span>
          </p>

          <Form className="d-flex justify-content-center">
            <Form.Check
              className="mb-5"
              type="checkbox"
              label="Ya realicé el pago"
              checked={paymentDone}
              onChange={(e) => setPaymentDone(e.target.checked)}
            />
          </Form>
          <Button
            className="mx-auto"
            disabled={!paymentDone}
            style={{ width: "50%" }}
            onClick={() => confirmPayment()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Confirmar Pago</>
            )}
          </Button>
        </Col>
      </Row>

      <Collapse
        style={{ position: "absolute", top: "30%", width: "100%" }}
        in={open}
      >
        <div>
          <Alert variant="danger" onClose={() => setOpen(false)} dismissible>
            <div>
              El pago no ha sido encontrado, asegurese de haber realizado el
              pago.
              <br />
              Si ya ha realizado el pago, espere el correo de confirmación e
              intente nuevamente.
            </div>
          </Alert>
        </div>
      </Collapse>
    </div>
  );
};

Zelle.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
};

export default Zelle;
