import React, { useState } from "react";
import { CloseButton, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { formatter } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../../../services";
import PropTypes from "prop-types";

const TransferUSD = ({ setMethod, user, payment }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [ref, setRef] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await API.base.createPayment({
      user,
      payment,
      method: "transferUSD",
      data: { ref },
    });
    if (res.status === 200) {
      navigate("/success", {
        state: { method: "transferUSD", payment, draft: true },
      });
    }
    setLoading(false);
  };

  const handleRefChange = (value) => {
    let newRef = value;
    newRef = newRef.replace(/\D/g, "");
    newRef = newRef.slice(0, 6);

    setRef(newRef);
  };
  const verifyData = () => ref == "" || ref.length < 6;

  return (
    <div>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      <Row>
        <Col className="d-flex mt-4 flex-column justify-content-center">
          <p className="mb-0">
            Realiza la Transferencia bancaria por el monto total:{" "}
            <span className="fw-bold">
              {formatter.format(payment.amount)} $
            </span>{" "}
            a:
          </p>
          <p className="fw-bold mb-0">Truist Bank</p>
          <p className="fw-bold mb-0">Plenico inc</p>
          <p className="mb-0">
            Número de cuenta: <span className="fw-bold">1100028250752</span>
          </p>
          <p className="mb-0">
            Routing number: <span className="fw-bold">263191387</span>
          </p>
          <p className="mb-0">
            Dirección:{" "}
            <span className="fw-bold">
              781 crandon BLVD apt 1406, key Biscayne, Fl 33149
            </span>
          </p>
          <p className="fw-bold mb-3">pagos@pleni.app</p>
          <p className="mb-0">
            Una vez realizado el pago, ingrese el número de referencia:{" "}
          </p>
          <Form className="m-4" onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="text-start">
              <Form.Label>Número de Referencia (últimos 6 digitos)</Form.Label>
              <Form.Control
                className="mb-3"
                name="ref"
                value={ref}
                onChange={(e) => handleRefChange(e.target.value)}
                placeholder="000000"
                required
              />
            </Form.Group>
            <Button
              disabled={verifyData()}
              variant="primary"
              type="submit"
              style={{ width: "50%" }}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Confirmar Transferencia</>
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

TransferUSD.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
};

export default TransferUSD;
