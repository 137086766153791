import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  return (
    <Navbar bg="white" variant="light" className="shadow-sm">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            width="102px"
            src="Pleni.png"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
