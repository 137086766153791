import React from "react";
import { Offcanvas, Accordion } from "react-bootstrap";
import parse from "html-react-parser";
import PropTypes from "prop-types";

const C2PModal = ({ showModal, setShowModal }) => {
  const banks = [
    {
      name: "Bancamiga Banco Universal",
      options: ["Desde la App Movil: <b>Bancamiga Suite</b>"],
    },
    {
      name: "Banco Mercantil",
      options: [
        "Desde la App Movil: <b>Tpago</b>",
        "Enviando SMS al <b>24024</b> con mensaje: <b>SCP</b>",
        "Mas informacion <a href='https://www.mercantilbanco.com/mercprod/content/personas/banca_movil/tpago.html?utm_source=webpage&utm_medium=slideshows&utm_term=home_1&utm_campaign=slideshow_home_1' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
    {
      name: "Banesco Banco Universal",
      options: [
        "Desde la Página Web: <b>Mis Solicitudes Banesco</b>",
        "Enviando SMS al <b>2846</b> con mensaje: <b>clave</b> <b>dinamica</b> (<b>V-E-J</b>)(número de identificación)",
        "Mas informacion <a href='https://www.banesco.com/personas/banca-digital-personas/banca-movil/banesco-pagomovil' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
    {
      name: "BBVA Provincial",
      options: [
        "Desde la App Movil: <b>DineroRapido</b>",
        "Mas informacion <a href='https://www.provincial.com/personas/servicios-digitales/dinero-rapido.html#generar-clave-de-compra' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
    {
      name: "Banco de Venezuela",
      options: ["Desde la Página Web: <b>PuntoYaBDV</b>"],
    },
    {
      name: "Bancaribe",
      options: [
        "Desde la App Movil: <b>Mi Pago Bancaribe</b>",
        "Enviando SMS al <b>22741</b> con mensaje: <b>Clavemipago</b>",
      ],
    },
    {
      name: "BOD",
      options: [
        "Enviando SMS al <b>263</b> con mensaje: <b>COMERCIO </b>(cedula de identidad)",
      ],
    },
    {
      name: "Banco Plaza",
      options: [
        "Desde la App Movil: <b>TuDineroYa</b>",
        "Desde la Página Web: <b>Tu Plaza en Línea</b>",
        "Enviando SMS al <b>1470</b> con mensaje: <b>CLAVE</b> (<b>V-E-P</b>) (número de identificación)",
        "Mas informacion <a href='http://www.bancoplaza.com/personas/tudineroya-p2p/' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
    {
      name: "Banco Nacional de Crédito",
      options: [
        "Desde la Página Web: <b>BNCNET</b>",
        "Mas informacion <a href='https://www.bncenlinea.com/productos-y-servicios/servicios' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
    {
      name: "Banco Exterior",
      options: [
        "Desde la App Movil: <b>Exterior NEXO</b>",
        "Enviando SMS al <b>278</b> con mensaje: <b>CLAVE</b> (<b>V-E-J</b>) (número de identificación)",
        "Max informacion <a href='https://www.bancoexterior.com/personas/canales-digitales/exterior-nexo-pago-movil/' target='_blank' rel='noreferrer'>aquí</a>",
      ],
    },
  ];

  return (
    <Offcanvas
      show={showModal}
      onHide={() => setShowModal(false)}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Como obtener mi código secreto C2P</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Accordion defaultActiveKey="0">
          {banks.map((bank, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{bank.name}</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {bank.options.map((option, index) => (
                    <li key={index}>{parse(option)}</li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <div className="text-end mt-5">
          Esta es información de <b>referencia</b>, <br />
          para mayor información consulte con su banco.
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

C2PModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default C2PModal;
