// STRIPE
export const CREATE_PAYMENT_INTENT = "/api/v1/stripe/createPaymentIntent";
export const GET_PAYMENT_METHODS = "/api/v1/stripe/getPaymentMethods";
export const DELETE_PAYMENT_METHOD = "/api/v1/stripe/deletePaymentMethod";

// BASE
export const CREATE_ZELLE_PAYMENT = "/api/v1/base/createZellePayment";
export const CREATE_PAYMENT = "/api/v1/base/createPayment";
export const GET_PAGOMOVIL_DATA = "/api/v1/base/getPagomovilData";
export const GET_C2P_DATA = "/api/v1/base/getC2PData";
export const CHECK_TOKEN = "/api/v1/base/checkToken";

// BANCAMIGA
export const FIND_PAGOMOVIL = "/api/v1/bancamiga/findPagomovil";
export const C2P_PAYMENT = "/api/v1/bancamiga/c2pPayment";

// BANCARIBE
export const FIND_PAYMENT = "/api/v1/bancaribe/findPayment";

// ODOO
export const GET_AMOUNTS = "/api/v1/odoo/getAmounts";
