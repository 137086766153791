import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel, Image, Modal, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { formatter } from "../../utils";
import "./OrderInfo.css";

const OrderInfo = ({ user, payment }) => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(1800);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onTimerEnd = () => {
    navigate("/timeOut");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevCount) => Math.max(prevCount - 1, 0));
    }, 1000);

    if (seconds === 0) {
      clearInterval(interval);
      onTimerEnd();
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const minute = Math.floor(seconds / 60);
  const second = seconds % 60;

  const invoicesTable = (invoices) => (
    <Table className="m-auto mb-2">
      <thead>
        <tr>
          <th className="text-primary fw-normal fs-5">Número</th>
          <th className="text-primary fw-normal fs-5">Orden</th>
          <th className="text-primary fw-normal fs-5">Monto</th>
        </tr>
      </thead>
      <tbody style={{ maxHeight: "150px" }}>
        {invoices.map((invoice) => (
          <tr key={invoice.id}>
            <td className="text">{invoice.name}</td>
            <td className="text">{invoice.order}</td>
            <td className="text">{formatter.format(invoice.amount)} $</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <Button
            className="ms-5"
            variant="outline-primary"
            onClick={handleShow}
          >
            Información
          </Button>
          <div className="me-5 border border-2 border-secondary border-opacity-75 rounded p-2">
            <i className="bi bi-hourglass"></i> {minute}:
            {second < 10 ? `0${second}` : second}
          </div>
        </div>
        <div
          className="d-flex flex-column align-items-start mb-5"
          style={{ width: "70%" }}
        >
          <p className="mb-0 fs-3 text-primary">Cliente:</p>
          <p className="fs-5 mb-4 ms-3">{user.name}</p>
          {payment.instantPayment && (
            <div className="mb-0 d-flex flex-column align-items-start">
              <p className="mb-0 fs-3 text-primary">Orden:</p>
              <p className="fs-5 ms-3">{payment.paidElements[0].name}</p>
            </div>
          )}
          {!payment.instantPayment && (
            <div
              className="mb-3 d-flex flex-column align-items-start"
              style={{ width: "100%" }}
            >
              <p className="mb-0 fs-3 text-primary">Facturas:</p>
              <div
                className="align-self-center"
                style={{ overflow: "auto", maxHeight: "150px", width: "100%" }}
              >
                {invoicesTable(payment.paidElements)}
              </div>
            </div>
          )}
          {payment.balance > 0 && (
            <div
              className="mb-3 d-flex flex-column align-items-start"
              style={{ width: "100%" }}
            >
              <p className="mb-0 fs-3 text-primary">Saldo a favor:</p>
              <p className="fs-5 mb-0 ms-3">
                {formatter.format(payment.balance)} $
              </p>
            </div>
          )}
          <p className="mb-0 fs-3 text-primary">Total a pagar:</p>
          <p className="fs-5 mb-0 ms-3">
            {formatter.format(payment.amount)} ${" "}
          </p>
          <p className="fs-5 mb-1 ms-3">
            {formatter.format(payment.amountBs)} Bs.
          </p>
          <p className="text-primary" style={{ fontSize: "14px" }}>
            Sólo podremos validar su pago si el monto es exacto. En caso de
            dudas comuníquese con{" "}
            <a href="https://wa.link/vhj2m6" target="_blank" rel="noreferrer">
              Atención al Cliente.
            </a>
          </p>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh" }}>
          <Carousel variant="dark">
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <Image
                  src="/tutorial1.jpg"
                  fluid
                  style={{ maxHeight: "70vh" }}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <Image
                  src="/tutorial2.jpg"
                  fluid
                  style={{ maxHeight: "70vh" }}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <Image
                  src="/tutorial3.jpg"
                  fluid
                  style={{ maxHeight: "70vh" }}
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

OrderInfo.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
};

export default OrderInfo;
