import API from "./config";
import {
  CREATE_PAYMENT_INTENT,
  DELETE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
} from "./urls";

// GET
const getPaymentMethods = async (id) =>
  await API.get(`${GET_PAYMENT_METHODS}/${id}`);

// POST
const createPaymentIntent = async (data) =>
  await API.post(CREATE_PAYMENT_INTENT, data);

// DELETE
const deletePaymentMethod = async (id) =>
  await API.delete(`${DELETE_PAYMENT_METHOD}/${id}`);

export default {
  createPaymentIntent,
  getPaymentMethods,
  deletePaymentMethod,
};
