import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const PaymentNotFound = () => {
  const [count, setCount] = useState(5);
  const url = sessionStorage.getItem("url");

  useEffect(() => {
    if (url) {
      const timer = setInterval(() => {
        setCount((prevCount) => Math.max(prevCount - 1, 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    if (count === 0) {
      window.location.href = `${process.env.REACT_APP_ODOO_URL}${url}`;
    }
  }, [count]);

  return (
    <Container
      className="bg-white my-4 p-5 shadow-sm h-100"
      style={{ maxWidth: "1050px" }}
    >
      <Row>
        <Col
          className="d-flex flex-column justify-content-center"
          style={{ height: 360 }}
        >
          <p className="text-warning" style={{ fontSize: "60px" }}>
            <i className="bi bi-exclamation-circle-fill"></i>
          </p>
          <h3>Tu pedido ha sido registrado con éxito.</h3>
          <h5>
            Sin embargo, el monto recibido no corresponde con el monto esperado.
            Por favor, ponte en contacto con nuestro servicio de atención al
            cliente.
          </h5>
          {url && (
            <h5>
              Seras redireccionado nuevamente al pedido en {count} segundos
            </h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentNotFound;
