import API from "./config";
import { FIND_PAGOMOVIL, C2P_PAYMENT } from "./urls";

// POST
const findPagomovil = async (data) => await API.post(FIND_PAGOMOVIL, data);
const c2pPayment = async (data) => await API.post(C2P_PAYMENT, data);

export default {
  findPagomovil,
  c2pPayment,
};
