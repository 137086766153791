import API from "./config";
import {
  CREATE_PAYMENT,
  CREATE_ZELLE_PAYMENT,
  GET_PAGOMOVIL_DATA,
  GET_C2P_DATA,
  CHECK_TOKEN,
} from "./urls";

// POST
const createZellePayment = async (data) =>
  await API.post(CREATE_ZELLE_PAYMENT, data);
const createPayment = async (data) => await API.post(CREATE_PAYMENT, data);

// GET
const getPagomovilData = async (id) =>
  await API.get(`${GET_PAGOMOVIL_DATA}/${id}`);
const getC2PData = async (id) => await API.get(`${GET_C2P_DATA}/${id}`);
const checkToken = async () => await API.get(CHECK_TOKEN);

export default {
  createZellePayment,
  createPayment,
  getPagomovilData,
  getC2PData,
  checkToken,
};
