import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TimeOut = () => {
  const navigate = useNavigate();

  const jwt = sessionStorage.getItem("jwt");

  const handlePress = () => {
    navigate(`/${jwt}`);
  };
  return (
    <Container
      className="bg-white my-4 p-5 shadow-sm h-100"
      style={{ maxWidth: "1050px" }}
    >
      <Row>
        <Col
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 360 }}
        >
          <p style={{ fontSize: "60px" }}>
            <i className="bi bi-hourglass-bottom"></i>
          </p>
          <h3>Se agotó el tiempo para su transacción</h3>
          <h5 className="mb-4">
            Puede intentar nuevamente abriendo de nuevo el enlace de pago o
            apretando el siguiente botón.
          </h5>
          <div>
            <Button className="px-5" onClick={() => handlePress()}>
              Pagar
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TimeOut;
