/* eslint-disable */
import React, { useState } from "react";
import {
  CloseButton,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  InputGroup,
  Alert,
  Collapse,
} from "react-bootstrap";
import { formatter } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../../../services";
import PropTypes from "prop-types";

const TransferBs = ({ setMethod, user, payment }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    dniPrefix: "V",
    dni: "",
    ref: "",
  });
  const [paymentDone, setPaymentDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (name == "dni" || name == "ref") {
      value = value.replace(/\D/g, "");
    } else if (name == "ref") {
      value = value.replace(/\D/g, "");
      value = value.slice(0, 9);
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const bancaribeData = {
      phone: "",
      dni: values.dniPrefix + values.dni,
      ref: values.ref,
      type: "TRF",
    };
    const { data } = await API.bancaribe.findPayment({
      user,
      payment,
      bancaribeData,
    });
    if (data.found === true) {
      navigate("/success", { state: { method: "transferBs", payment } });
    } else if (data.found === false) {
      const res = await API.base.createPayment({
        user,
        payment,
        method: "transferBs",
        data: { ref: values.ref },
      });
      if (res.status === 200) {
        navigate("/success", {
          state: { method: "transferBs", payment, draft: true },
        });
      } else {
        setOpen(true);
      }
    } else if (data.found === "noMatch") {
      navigate("/paymentNotFound");
    }
    setLoading(false);
  };

  const dniOptions = () =>
    dniPrefixes.map((prefix) => (
      <option value={prefix.value} key={prefix.value}>
        {prefix.label}
      </option>
    ));

  const verifyData = () =>
    !paymentDone ||
    Object.values(values).some((value) => value === "") ||
    values.ref.length < 4;

  const dniPrefixes = [
    {
      label: "V",
      value: "V",
    },
    {
      label: "J",
      value: "J",
    },
    {
      label: "E",
      value: "E",
    },
    {
      label: "P",
      value: "P",
    },
    {
      label: "G",
      value: "G",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      <Row>
        <Col className="d-flex mt-4 flex-column justify-content-center">
          <p className="mb-0">
            Realiza la Transferencia bancaria por el monto total:{" "}
            <span className="fw-bold">
              {formatter.format(payment.amountBs)} Bs.
            </span>{" "}
            a:
          </p>
          <p className="fw-bold mb-0">Bancaribe C.A. Banco Universal.</p>
          <p className="fw-bold mb-0">0114 0302 6830 2008 1960</p>
          <p className="fw-bold mb-0">J-50065903-2</p>
          <p className="fw-bold mb-0">Agricola Los Gochos CA</p>
          <p className="fw-bold mb-3">0414 9732329</p>
          <p className="mb-0">
            Una vez realizado el pago, complete el siguiente formulario:{" "}
          </p>
          <Form className="m-4" onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="text-start">
              <Form.Label>Documento de Identidad</Form.Label>
              <InputGroup className="mb-1">
                <Form.Select
                  name="dniPrefix"
                  value={values.dniPrefix}
                  style={{ maxWidth: "20%" }}
                  onChange={(e) => handleInputChange(e)}
                >
                  {dniOptions()}
                </Form.Select>
                <Form.Control
                  name="dni"
                  value={values.dni}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="10000000"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="text-start">
              <Form.Label>Número de Referencia</Form.Label>
              <Form.Control
                name="ref"
                value={values.ref}
                onChange={(e) => handleInputChange(e)}
                placeholder="0000"
                required
              />
              <Form.Text className="text-muted">
                Con los últimos 4 dígitos es suficiente. (En caso de ser desde
                Bancaribe, debe colocar todos los dígitos)
              </Form.Text>
            </Form.Group>
            <Form.Check
              className="mb-3 text-start"
              type="checkbox"
              label="Ya realicé el pago"
              checked={paymentDone}
              onChange={(e) => setPaymentDone(e.target.checked)}
            />
            <Button
              disabled={verifyData()}
              variant="primary"
              type="submit"
              style={{ width: "50%" }}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Confirmar Transferencia</>
              )}
            </Button>
          </Form>
        </Col>
      </Row>

      <Collapse
        style={{ position: "absolute", top: "30%", width: "100%" }}
        in={open}
      >
        <div>
          <Alert variant="danger" onClose={() => setOpen(false)} dismissible>
            <div>
              La transferencia no ha sido encontrado, asegurese de haber
              realizado el pago.
              <br />
              Si ya ha realizado el pago, espere unos segundos e intente
              nuevamente.
            </div>
          </Alert>
        </div>
      </Collapse>
    </div>
  );
};

TransferBs.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
};

export default TransferBs;
