import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Forbidden = () => {
  return (
    <Container
      className="bg-white my-4 p-5 shadow-sm h-100"
      style={{ maxWidth: "1050px" }}
    >
      <Row>
        <Col
          className="d-flex flex-column justify-content-center"
          style={{ height: 360 }}
        >
          <p className="text-danger" style={{ fontSize: "60px" }}>
            <i className="bi bi-x-circle-fill"></i>
          </p>
          <h3>Enlace no Autorizado o Expirado</h3>
          <h5>
            Comuníquese con Atención al cliente para obtener un enlace válido
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

export default Forbidden;
