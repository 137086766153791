import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import API from "../../../../services";
import CheckoutForm from "./CheckoutForm";
import { CloseButton } from "react-bootstrap";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const Stripe = ({ user, payment, auth, setMethod }) => {
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentIntent = async () => {
    const res = await API.stripe.createPaymentIntent({ user, payment, auth });
    const { data } = res;
    setClientSecret(data.clientSecret);
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm payment={payment} />
        </Elements>
      )}
    </div>
  );
};

Stripe.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  auth: PropTypes.bool,
  setMethod: PropTypes.func,
};

export default Stripe;
