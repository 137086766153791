import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Payment from "./components/paymentBox/Payment";
import Forbidden from "./components/forbidden/Forbidden";
import Success from "./components/success/Success";
import PaymentNotFound from "./components/paymentNotFound/PaymentNotFound";
import UsedToken from "./components/usedToken/UsedToken";
import TimeOut from "./components/timeOut/TimeOut";

const App = () => {
  return (
    <div
      className="App pb-5"
      style={{
        position: "relative",
        background: "#FCFDFF",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Routes>
        <Route exact path="/" element={<Forbidden />} />
        <Route path="/success" element={<Success />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/paymentNotFound" element={<PaymentNotFound />} />
        <Route path="/usedToken" element={<UsedToken />} />
        <Route path="/timeOut" element={<TimeOut />} />
        <Route path="/:JWT" element={<Payment />} />
      </Routes>
    </div>
  );
};

export default App;
