import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Success = () => {
  const { state } = useLocation();

  const [count, setCount] = useState(5);
  const url = sessionStorage.getItem("url");

  useEffect(() => {
    if (url) {
      const timer = setInterval(() => {
        setCount((prevCount) => Math.max(prevCount - 1, 0));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    if (count === 0) {
      window.location.href = `${process.env.REACT_APP_ODOO_URL}${url}`;
    }
  }, [count]);

  const paymentMethods = {
    stripe: "Tarjeta Internacional",
    zelle: "Zelle",
    pagomovil: "PagoMóvil",
    c2p: "C2P",
    transferBs: "Transferencia Bs.",
    transferUSD: "Transferencia USD",
    pos: "Punto de Venta",
    cash: "Efectivo",
  };

  const messages = {
    cash: "¡Prepara tu pago! Nuestro despachador PLENI recogerá el pago al momento de la entrega.",
    pos: "¡Prepara tu pago! Nuestro despachador PLENI procesará el pago al momento de la entrega.",
    draft:
      "Nuestro equipo administrativo estará validando la transacción. En caso de inconvenientes se pondrá en contacto con usted.",
  };

  return (
    <Container
      className="bg-white my-4 p-5 shadow-sm h-100"
      style={{ maxWidth: "1050px" }}
    >
      <Row>
        <Col
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 360 }}
        >
          {state?.draft ? (
            <>
              <i
                className="bi bi-exclamation-circle"
                style={{ color: "gold", fontSize: 100 }}
              ></i>
              <h3 className="mb-4">Gracias por reportar tu pago.</h3>
            </>
          ) : (
            <>
              <i
                className="bi bi-check-circle"
                style={{ color: "green", fontSize: 100 }}
              ></i>
              <h3 className="mb-4">Tu pago ha sido registrado con éxito.</h3>
            </>
          )}
          {state?.draft && <h5>{messages["draft"]}</h5>}
          <h5>{messages[state?.method]}</h5>
          <div style={{ minWidth: "50%" }}>
            <div className="d-flex justify-content-between">
              <div>Monto:</div>
              <div>{state?.payment?.amount}$</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Fecha:</div>
              <div>{new Date().toLocaleDateString()}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                {state?.payment?.instantPayment ? "Orden:" : "Facturas:"}
              </div>
              <div>
                {state?.payment?.paidElements
                  ?.map((element) => element.name)
                  .join(", ")}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Método de pago:</div>
              <div>{paymentMethods[state?.method]}</div>
            </div>
          </div>

          {url && (
            <h5>
              Seras redireccionado nuevamente al pedido en {count} segundos
            </h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Success;
