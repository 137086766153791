import React, { useState } from "react";
import {
  CloseButton,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Spinner,
  Alert,
  Collapse,
} from "react-bootstrap";
import { formatter, banks } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import API from "../../../../services";
import PropTypes from "prop-types";
import { useEffect } from "react";
import "./index.css";
import C2PModal from "./C2PModal";

const C2P = ({ setMethod, user, payment }) => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    phone: "",
    phonePrefix: "",
    bank: "",
    dni: "",
    dniPrefix: "",
    otp: "",
  });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (name == "phone") {
      value = value.replace(/\D/g, "");
      value = value.slice(0, 7);
    } else if (name == "dni") {
      value = value.replace(/\D/g, "");
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const c2pData = {
      bank: values.bank,
      phone: values.phonePrefix + values.phone,
      dni: values.dniPrefix + values.dni,
      otp: values.otp,
    };
    const { data } = await API.bancamiga.c2pPayment({ user, payment, c2pData });
    if (data.success) {
      navigate("/success", { state: { method: "c2p", payment } });
    } else {
      setShowAlert(true);
    }
    setLoading(false);
  };

  const banksOptions = () =>
    banks.map((bank) => (
      <option value={bank.id} key={bank.id}>
        {bank.name}
      </option>
    ));

  const prefixOptions = () =>
    phonePrefixes.map((prefix) => (
      <option value={prefix.value} key={prefix.value}>
        {prefix.label}
      </option>
    ));

  const dniOptions = () =>
    dniPrefixes.map((prefix) => (
      <option value={prefix.value} key={prefix.value}>
        {prefix.label}
      </option>
    ));

  const verifyData = () =>
    Object.values(values).some((value) => value === "") ||
    values.phone.length != 7;

  const fetchC2PData = async () => {
    const { data } = await API.base.getC2PData(user.id);
    setValues({
      phone: data.phone.slice(5, 12),
      phonePrefix: data.phone.slice(0, 5) || "58414",
      bank: data.bank,
      dniPrefix: data.dni.slice(0, 1) || "V",
      dni: data.dni.slice(1),
    });
  };

  useEffect(() => {
    fetchC2PData();
  }, []);

  const phonePrefixes = [
    {
      label: "0414",
      value: "58414",
    },
    {
      label: "0424",
      value: "58424",
    },
    {
      label: "0412",
      value: "58412",
    },
    {
      label: "0416",
      value: "58416",
    },
    {
      label: "0426",
      value: "58426",
    },
  ];

  const dniPrefixes = [
    {
      label: "V",
      value: "V",
    },
    {
      label: "J",
      value: "J",
    },
    {
      label: "E",
      value: "E",
    },
    {
      label: "P",
      value: "P",
    },
    {
      label: "G",
      value: "G",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex">
        <CloseButton onClick={() => setMethod("")} />
      </div>
      <Row>
        <Col className="d-flex flex-column justify-content-center">
          <p className="mb-0">Solicita a tu banco tu código secreto C2P</p>
          <p className="mb-2">
            por el monto de{" "}
            <span className="fw-bold">
              {formatter.format(payment.amountBs)} Bs.
            </span>
          </p>
          <p className="mb-0">
            Una vez recibido el código, complete el siguiente formulario:{" "}
          </p>
          <Form className="m-4" onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="text-start">
              <Form.Label>Documento de Identidad</Form.Label>
              <InputGroup className="mb-1">
                <Form.Select
                  name="dniPrefix"
                  value={values.dniPrefix}
                  style={{ maxWidth: "20%" }}
                  onChange={(e) => handleInputChange(e)}
                >
                  {dniOptions()}
                </Form.Select>
                <Form.Control
                  name="dni"
                  value={values.dni}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="10000000"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="text-start">
              <Form.Label>Número de teléfono</Form.Label>
              <InputGroup className="mb-1">
                <Form.Select
                  name="phonePrefix"
                  value={values.phonePrefix}
                  style={{ maxWidth: "20%" }}
                  onChange={(e) => handleInputChange(e)}
                >
                  {prefixOptions()}
                </Form.Select>
                <Form.Control
                  name="phone"
                  value={values.phone}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="0001111"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="text-start">
              <Form.Label>Banco de Origen</Form.Label>
              <Form.Select
                className="mb-1"
                name="bank"
                value={values.bank}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Seleccione banco de origen</option>
                {banksOptions()}
              </Form.Select>
            </Form.Group>
            <Form.Group className="text-start">
              <Form.Label>Código secreto C2P</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  name="otp"
                  value={values.otp}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="****"
                  required
                />
                <Button onClick={() => setShowModal(true)}>
                  <i className="bi bi-exclamation-circle"></i>
                </Button>
              </InputGroup>
            </Form.Group>
            <Button
              disabled={verifyData()}
              variant="primary"
              type="submit"
              style={{ width: "50%" }}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Pagar</>
              )}
            </Button>
          </Form>
        </Col>
      </Row>

      <Collapse style={{ position: "absolute", top: "0px" }} in={showAlert}>
        <div>
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <p>
              El pago no pudo ser procesado. Intentelo nuevamente. Si el error
              persiste comuniquese con Atención al Cliente.
            </p>
          </Alert>
        </div>
      </Collapse>
      <C2PModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

C2P.propTypes = {
  user: PropTypes.any.isRequired,
  payment: PropTypes.any.isRequired,
  setMethod: PropTypes.func,
};

export default C2P;
