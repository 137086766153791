import * as jose from "jose";
import { SHA256 } from "crypto-js";

// ****************************************************************************************************
// JWT functions.
// ****************************************************************************************************
const enc = new TextEncoder();
export const verifyJWT = async (token) =>
  await jose.jwtVerify(token, enc.encode(process.env.REACT_APP_JWT_SECRET));

// ****************************************************************************************************
// Formatter functions.
// ****************************************************************************************************
export const formatter = new Intl.NumberFormat("us-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

// ****************************************************************************************************
// ZelleToken functions.
// ****************************************************************************************************
export const generateToken = (JWT, tokenLength = 4) => {
  const {
    user: { id: userID },
    payment: {
      paidElements: payElements,
      amount: payAmount,
      amountBs: payAmountBs,
    },
  } = JWT;

  const sumElements = payElements.reduce(
    (acc, curr) => acc + curr.amount * (parseInt(curr.id) / (acc + 1)),
    0,
  );

  const x = userID * (payAmountBs / payAmount);
  const pow = Math.pow(x, payElements.length);

  const seed = (pow * sumElements).toString();

  const hash = SHA256(seed).toString();
  const number = hash.replace(/\D/g, "");
  return number.slice(number.length - tokenLength);
};

// ****************************************************************************************************
// Banks Array.
// ****************************************************************************************************
export const banks = [
  {
    id: "0102",
    name: "Banco De Venezuela S.A. Banco Universal",
  },
  {
    id: "0104",
    name: "Banco Venezolano De Credito S.A.",
  },
  {
    id: "0105",
    name: "Banco Mercantil C.A.",
  },
  {
    id: "0108",
    name: "Banco Provincial BBVA",
  },
  {
    id: "0114",
    name: "Bancaribe C.A. Banco Universal",
  },
  {
    id: "0115",
    name: "Banco Exterior C.A.",
  },
  {
    id: "0128",
    name: "Banco Caroni, C.A. Banco Universal",
  },
  {
    id: "0134",
    name: "Banesco Banco Universal",
  },
  {
    id: "0137",
    name: "Banco Sofitasa",
  },
  {
    id: "0138",
    name: "Banco Plaza",
  },
  {
    id: "0145",
    name: "Banco De Comercio Exterior",
  },
  {
    id: "0151",
    name: "Banco Fondo Común C.A. Banco Universal",
  },
  {
    id: "0156",
    name: "100%Banco",
  },
  {
    id: "0157",
    name: "Del Sur Banco Universal",
  },
  {
    id: "0163",
    name: "Banco Del Tesoro",
  },
  {
    id: "0166",
    name: "Banco Agrícola De Venezuela",
  },
  {
    id: "0168",
    name: "Bancrecer S.A. Banco De Desarrollo",
  },
  {
    id: "0169",
    name: "MiBanco Banco De Desarrollo, C.A.",
  },
  {
    id: "0171",
    name: "Banco Activo Banco Comercial, C.A.",
  },
  {
    id: "0172",
    name: "Bancamiga Banco Universal, C.A.",
  },
  {
    id: "0174",
    name: "Banplus Banco Universal C.A.",
  },
  {
    id: "0175",
    name: "Banco Bicentenario Banco Universal C.A.",
  },
  {
    id: "0177",
    name: "Banco De La Fuerza Armada Nacional Bolivariana.",
  },
  {
    id: "0191",
    name: "Banco Nacional De Credito",
  },
];
